<template>
    <b-container fluid>
        <iq-card>
           <template v-slot:headerTitle>
              <h4 class="card-title">{{ $t('bsri_config.mill_subzone_center_unit_no_wise_cda_mapping') }} {{ $t('globalTrans.search') }}</h4>
            </template>
            <template v-slot:body>
                <b-row>
                    <b-col lg="6" sm="12">
                      <b-form-group
                          class="row"
                          label-cols-sm="4"
                          label-for="fiscal_year_id"
                      >
                        <template v-slot:label>
                          {{$t('elearning_config.fiscal_year')}}
                        </template>
                        <v-select name="organization"
                          v-model="search.fiscal_year_id"
                          label="text"
                          :reduce="item => item.value"
                          :options= fiscalYearList
                          :placeholder="$t('globalTrans.select')"
                        />
                      </b-form-group>
                    </b-col>
                    <b-col xs="12" sm="12" md="6" lg="6" xl="6" >
                        <b-form-group
                            class="row"
                            label-cols-sm="4"
                            label-for="season_id"
                            >
                            <template v-slot:label>
                                {{$t('dae_config.season_name')}}
                            </template>
                            <v-select name="season_id"
                              v-model="search.season_id"
                              label="text"
                              :reduce="item => item.value"
                              :options= seasonSetupList
                              :placeholder="$t('globalTrans.select')"
                            />
                        </b-form-group>
                    </b-col>
                    <b-col xs="12" sm="12" md="6" lg="6" xl="6" >
                      <b-form-group
                          class="row"
                          label-cols-sm="4"
                          label-for="subsidy_type_id"
                          >
                          <template v-slot:label>
                          {{ $t('bsri_config.subsidy_type') }}
                          </template>
                          <v-select name="subsidy_type_id"
                            v-model="search.subsidy_type_id"
                            label="text"
                            :reduce="item => item.value"
                            :options= subsidyTypeList
                            :placeholder="$t('globalTrans.select')"
                          />
                      </b-form-group>
                    </b-col>
                    <b-col xs="12" sm="12" md="6" lg="6" xl="6" >
                      <b-form-group
                          class="row"
                          label-cols-sm="4"
                          label-for="subsidy_id"
                          >
                          <template v-slot:label>
                            {{ $t('bsri_config.subsidy_name') }}
                          </template>
                          <v-select name="subsidy_id"
                            v-model="search.subsidy_id"
                            label="text"
                            :reduce="item => item.value"
                            :options= subsidyList
                            :placeholder="$t('globalTrans.select')"
                          />
                      </b-form-group>
                    </b-col>
                    <b-col lg="12" sm="12" class="text-right">
                        <b-button type="button" variant="primary" @click="searchData">{{ $t('globalTrans.search')}}</b-button>
                    </b-col>
                </b-row>
            </template>
        </iq-card>
        <b-row>
            <b-col md="12">
                <iq-card>
                    <template v-slot:headerTitle>
                        <h4 class="card-title">{{ $t('bsri_config.mill_subzone_center_unit_no_wise_cda_mapping') }} {{ $t('globalTrans.list') }}</h4>
                    </template>
                    <template v-slot:headerAction>
                      <router-link to="cda-mapping-form" :class="'btn btn-success text-light'">{{  $t('globalTrans.add_new') }}</router-link>
                    </template>
                    <template v-slot:body>
                        <b-overlay :show="loadingState">
                            <b-row>
                                <b-col md="12" class="table-responsive">
                                    <b-table bordered hover :items="listData" :fields="columns" aria-hidden="loading | listReload ? 'true' : null" :emptyText="$t('globalTrans.noDataFound')" show-empty>
                                        <template v-slot:cell(index)="data">
                                        {{ $n(data.index + pagination.slOffset) }}
                                        </template>
                                        <template v-slot:cell(status)="data">
                                          <span class="badge badge-info" v-if="data.item.status == 1">{{$t('globalTrans.pending')}}</span>
                                          <span class="badge badge-success" v-else-if="data.item.status == 2">{{$t('globalTrans.approved')}}</span>
                                          <span class="badge badge-danger" v-else>{{$t('globalTrans.reject')}}</span>
                                        </template>
                                        <template v-slot:cell(action)="data">
                                          <b-button v-b-modal.modal-4 variant="btn btn-sm btn-success" size="sm" @click="edit(data.item)">
                                            <i class="fas fa-eye"></i>
                                          </b-button>
                                          <router-link title="Edit" :to="`/incentive-grant-service/dae/grant-allocation-distribution/district-approval-committee-resolution-form?id=${data.item.id}`" class="btn btn-sm btn-warning"><i class="ri-ball-pen-fill m-0"></i></router-link>
                                        </template>
                                    </b-table>
                                    <b-pagination
                                        v-model="pagination.currentPage"
                                        :per-page="pagination.perPage"
                                        :total-rows="pagination.totalRows"
                                        @input="searchData"
                                        />
                                </b-col>
                            </b-row>
                        </b-overlay>
                    </template>
                </iq-card>
            </b-col>
            <!-- <pre>{{listData}}</pre> -->
        </b-row>
        <b-modal id="modal-4" size="lg" :title="$t('bsri_config.mill_subzone_center_unit_no_wise_cda_mapping') + ' ' + $t('globalTrans.details')" :ok-title="$t('globalTrans.close')" ok-only ok-variant="danger">
            <template #modal-title class="flex-grow-1">
            {{ $t('bsri_config.mill_subzone_center_unit_no_wise_cda_mapping') + ' ' + $t('globalTrans.details') }}
          </template>
            <Details :id="editItemId" :key="editItemId"  ref="details"/>
        </b-modal>
    </b-container>
</template>
<script>
import { core } from '@/config/pluginInit'
import RestApi, { incentiveGrantServiceBaseUrl } from '@/config/api_config'
import { districtApprovalCommitteeResolutionList } from '../../api/routes'
import ModalBaseMasterList from '@/mixins/modal-base-master-list'
import Details from './Details'

export default {
    mixins: [ModalBaseMasterList],
    components: {
      Details
    },
    data () {
        return {
          search: {
            type: 'District',
            season_id: 0,
            subsidy_type_id: 0,
            subsidy_id: 0,
            fiscal_year_id: 0
          },
          subsidyList: [],
          allocationList: []
        }
    },
    computed: {
      fiscalYearList: function () {
        return this.$store.state.commonObj.fiscalYearList.filter(item => item.status === 0)
      },
      subsidyTypeList: function () {
        const dataList = this.$store.state.incentiveGrant.commonObj.subsidyTypeList.filter(item => item.status === 1)
        return dataList
      },
      seasonSetupList: function () {
        return this.$store.state.incentiveGrant.commonObj.seasonSetupList
      },
      formTitle () {
          return (this.editItemId === 0) ? this.$t('elearning_config.trainer_evaluation') + ' ' + this.$t('globalTrans.entry') : this.$t('elearning_config.trainer_evaluation') + ' ' + this.$t('globalTrans.update')
      },
      columns () {
          const labels = [
              { label: this.$t('globalTrans.sl_no'), class: 'text-left' },
              { label: this.$t('globalTrans.fiscal_year'), class: 'text-left' },
              { label: this.$t('dae_config.season_name'), class: 'text-left' },
              { label: this.$t('dae_config.subsidy_type'), class: 'text-left' },
              { label: this.$t('dae_config.subsidy_name'), class: 'text-left' },
              { label: this.$t('bsri_config.allocation_id'), class: 'text-center' },
              { label: this.$t('bsri_config.approval_commitee_name'), class: 'text-center' },
              { label: this.$t('globalTrans.action'), class: 'text-center' }
          ]

          let keys = []

          if (this.$i18n.locale === 'bn') {
              keys = [
              { key: 'index' },
              { key: 'fiscal_year_bn' },
              { key: 'season_bn' },
              { key: 'subsidy_type_bn' },
              { key: 'subsidy_bn' },
              { key: 'allocation_id' },
              { key: 'approval_committee_name_bn' },
              { key: 'action' }
              ]
          } else {
              keys = [
              { key: 'index' },
              { key: 'fiscal_year' },
              { key: 'season' },
              { key: 'subsidy_type' },
              { key: 'subsidy' },
              { key: 'allocation_id' },
              { key: 'approval_committee_name' },
              { key: 'action' }
              ]
          }

          return labels.map((item, index) => {
              return Object.assign(item, keys[index])
          })
      }
    },
    async created () {
        this.loadData()
    },
    mounted () {
        core.index()
    },
    watch: {
      'search.subsidy_type_id': function (newValue, OldValue) {
        this.subsidyList = this.getSubsidyList(newValue)
      }
    },
    methods: {
      getSubsidyList (subsidyTypeId) {
        const subsidyList = this.$store.state.incentiveGrant.commonObj.subsidyList.filter(item => item.status === 1 && item.subsidy_type_id === subsidyTypeId)
        return subsidyList
      },
      getCategoryList (typeId) {
        const trainingCategoryList = this.$store.state.TrainingElearning.commonObj.trainingCategoryList.filter(item => item.status === 1)
        if (typeId) {
          return trainingCategoryList.filter(item => item.training_type_id === typeId)
        }
        return trainingCategoryList
      },
      geVenueList (orgId) {
        const venueList = this.$store.state.TrainingElearning.commonObj.venus.filter(item => item.status === 1)
        if (orgId) {
          return venueList.filter(item => item.org_id === orgId)
        }
        return venueList
      },
      dataChange () {
          this.loadData()
      },
      async searchData () {
          this.loadData()
      },
      loadData () {
          const params = Object.assign({}, this.search, { page: this.pagination.currentPage, per_page: this.pagination.perPage })
          this.$store.dispatch('mutateCommonProperties', { loading: true, listReload: false })
          RestApi.getData(incentiveGrantServiceBaseUrl, districtApprovalCommitteeResolutionList, params).then(response => {
              if (response.success) {
                  this.$store.dispatch('setList', this.getCustomDataList(response.data.data))
                  this.paginationData(response.data)
              }
              this.$store.dispatch('mutateCommonProperties', { loading: false, listReload: false })
          })
      },
      getCustomDataList (data) {
        const listData = data.map(item => {
          const fiscalYearObj = this.$store.state.commonObj.fiscalYearList.find(doc => doc.value === parseInt(item.fiscal_year_id))
          const fiscalYearData = {}
          if (typeof fiscalYearObj !== 'undefined') {
            fiscalYearData.fiscal_year = fiscalYearObj.text_en
            fiscalYearData.fiscal_year_bn = this.$n(fiscalYearObj.text_bn.split('-')[0], { useGrouping: false }) + '-' + this.$n(fiscalYearObj.text_bn.split('-')[0], { useGrouping: false })
          } else {
            fiscalYearData.fiscal_year = ''
            fiscalYearData.fiscal_year_bn = ''
          }
          const seasonSetupListObj = this.$store.state.incentiveGrant.commonObj.seasonSetupList.find(seasonSetupList => seasonSetupList.value === parseInt(item.season_id))
          const seasonSetupListObjData = {}
          if (typeof seasonSetupListObj !== 'undefined') {
            seasonSetupListObjData.season = seasonSetupListObj.text_en
            seasonSetupListObjData.season_bn = seasonSetupListObj.text_bn
          } else {
            seasonSetupListObjData.season = ''
            seasonSetupListObjData.season_bn = ''
          }
          const subsidyTypeListObj = this.$store.state.incentiveGrant.commonObj.subsidyTypeList.find(subsidyTypeList => subsidyTypeList.value === parseInt(item.subsidy_type_id))
          const subsidyTypeListObjData = {}
          if (typeof subsidyTypeListObj !== 'undefined') {
            subsidyTypeListObjData.subsidy_type = subsidyTypeListObj.text_en
            subsidyTypeListObjData.subsidy_type_bn = subsidyTypeListObj.text_bn
          } else {
            subsidyTypeListObjData.subsidy_type = ''
            subsidyTypeListObjData.subsidy_type_bn = ''
          }
          const subsidyListObj = this.$store.state.incentiveGrant.commonObj.subsidyList.find(subsidyList => subsidyList.value === parseInt(item.subsidy_id))
          const subsidyListObjData = {}
          if (typeof subsidyListObj !== 'undefined') {
            subsidyListObjData.subsidy = subsidyListObj.text_en
            subsidyListObjData.subsidy_bn = subsidyListObj.text_bn
          } else {
            subsidyListObjData.subsidy = ''
            subsidyListObjData.subsidy_bn = ''
          }
          const projectListObj = this.$store.state.incentiveGrant.commonObj.projectList.find(projectList => projectList.value === parseInt(item.project_id))
          const projectListObjData = {}
          if (typeof projectListObj !== 'undefined') {
            projectListObjData.project = projectListObj.text_en
            projectListObjData.project_bn = projectListObj.text_bn
          } else {
            projectListObjData.project = ''
            projectListObjData.project_bn = ''
          }
          return Object.assign({}, item, fiscalYearData, seasonSetupListObjData, subsidyTypeListObjData, subsidyListObjData, projectListObjData)
        })
        return listData
      },
      pdfExport () {
        this.$refs.details.pdfExport()
      }
    }
}
</script>
