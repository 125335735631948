// Mill Information
export const MillInformationBase = '/bsri/config/bsri-ms-mill-infos'
export const MillInformationList = MillInformationBase + '/list'
export const MillInformationStatus = MillInformationBase + '/toggle-status'
export const MillInformationStore = MillInformationBase + '/store'
export const MillInformationUpdate = MillInformationBase + '/update'
export const MillInformationDestroy = MillInformationBase + '/destroy'

// mill Type
export const MillTypeBase = '/bsri/config/mill-types'
export const millTypeList = MillTypeBase + '/list'
export const millTypeStore = MillTypeBase + '/store'
export const millTypeShow = MillTypeBase + '/show'
export const millTypeUpdate = MillTypeBase + '/update'
export const millTypeStatus = MillTypeBase + '/toggle-status'
export const millTypeDestroy = MillTypeBase + '/destroy'
// Subsidy Type Setup
export const subsidyTypeBase = '/bsri/config/subsidy-types'
export const subsidyTypeList = subsidyTypeBase + '/list'
export const subsidyTypeStore = subsidyTypeBase + '/store'
export const subsidyTypeShow = subsidyTypeBase + '/show'
export const subsidyTypeUpdate = subsidyTypeBase + '/update'
export const subsidyTypeStatus = subsidyTypeBase + '/toggle-status'
export const subsidyTypeDestroy = subsidyTypeBase + '/destroy'
// crop name
export const cropNameBase = '/bsri/config/crops'
export const cropnameList = cropNameBase + '/list'
export const cropnameStore = cropNameBase + '/store'
export const cropnameShow = cropNameBase + '/show'
export const cropnameUpdate = cropNameBase + '/update'
export const cropnameStatus = cropNameBase + '/toggle-status'
export const cropnameDestroy = cropNameBase + '/destroy'

// cultivation method setup
export const cultivationMethods = '/bsri/config/cultivation-methods'
export const cultiMethodSetupList = cultivationMethods + '/list'
export const cultiMethodSetupStore = cultivationMethods + '/store'
export const cultiMethodSetupShow = cultivationMethods + '/show'
export const cultiMethodSetupUpdate = cultivationMethods + '/update'
export const cultiMethodSetupStatus = cultivationMethods + '/toggle-status'
export const cultiMethodSetupDestroy = cultivationMethods + '/destroy'
// Subsidy Setup
export const subsidySetupBase = '/bsri/config/subsidies'
export const subsidySetupList = subsidySetupBase + '/list'
export const subsidySetupStore = subsidySetupBase + '/store'
export const subsidySetupShow = subsidySetupBase + '/show'
export const subsidySetupUpdate = subsidySetupBase + '/update'
export const subsidySetupStatus = subsidySetupBase + '/toggle-status'
export const subsidySetupDestroy = subsidySetupBase + '/destroy'
// Cultivation Policy Setup
export const CultivationPolicySetupBase = '/bsri/config/cultivation-policy-setup'
export const CultivationPolicySetupList = CultivationPolicySetupBase + '/list'
export const CultivationPolicySetupToggle = CultivationPolicySetupBase + '/toggle-status'
export const CultivationPolicySetupStore = CultivationPolicySetupBase + '/store'
export const CultivationPolicySetupUpdate = CultivationPolicySetupBase + '/update'
export const CultivationPolicySetupDestroy = CultivationPolicySetupBase + '/destroy'

// project setup
export const projectSetup = '/bsri/config/projects'
export const projectSetupList = projectSetup + '/list'
export const projectSetupStore = projectSetup + '/store'
export const projectSetupShow = projectSetup + '/show'
export const projectSetupUpdate = projectSetup + '/update'
export const projectSetupStatus = projectSetup + '/toggle-status'
export const projectSetupDestroy = projectSetup + '/destroy'

// BSRI method setup
export const bsriRWMMethods = '/bsri/config/bsri-region-wise-mill-mappin'
export const rwmMapingList = bsriRWMMethods + '/list'
export const rwmMapingStore = bsriRWMMethods + '/store'
export const rwmMapingShow = bsriRWMMethods + '/show'
export const rwmMapingUpdate = bsriRWMMethods + '/update'
export const rwmMapingStatus = bsriRWMMethods + '/toggle-status'
export const rwmMapingDestroy = bsriRWMMethods + '/destroy'

// BSRI project subsidy mapping
export const projectSubsidy = '/bsri/config/project-subsidy'
export const projectSubsidyList = projectSubsidy + '/list'
export const projectSubsidyStore = projectSubsidy + '/store'
export const projectSubsidyShow = projectSubsidy + '/show'
export const projectSubsidyUpdate = projectSubsidy + '/update'
export const projectSubsidyStatus = projectSubsidy + '/toggle-status'
export const projectSubsidyDestroy = projectSubsidy + '/destroy'

// BSRI checklist
export const checklistBaseUrl = '/bsri/config/checklists'
export const checklistList = checklistBaseUrl + '/list'
export const checklistStore = checklistBaseUrl + '/store'
export const checklistShow = checklistBaseUrl + '/show'
export const checklistUpdate = checklistBaseUrl + '/update'
export const checklistStatus = checklistBaseUrl + '/toggle-status'
export const checklistDestroy = checklistBaseUrl + '/destroy'

// BSRI Unit list
export const unitlistBaseUrl = '/bsri/config/unitlists'
export const unitList = unitlistBaseUrl + '/list'
export const unitlistStore = unitlistBaseUrl + '/store'
export const unitlistUpdate = unitlistBaseUrl + '/update'
export const unitlistStatus = unitlistBaseUrl + '/toggle-status'
// BSRI Subzone Office Setup
export const subzoneOfficeBaseUrl = '/bsri/config/subzone-office'
export const subzoneOfficeList = subzoneOfficeBaseUrl + '/list'
export const subzoneOfficeStore = subzoneOfficeBaseUrl + '/store'
export const subzoneOfficeUpdate = subzoneOfficeBaseUrl + '/update'
export const subzoneOfficeStatus = subzoneOfficeBaseUrl + '/toggle-status'

// BSRI Center Setup
export const centerBaseUrl = '/bsri/config/center-list'
export const centerList = centerBaseUrl + '/list'
export const centerStore = centerBaseUrl + '/store'
export const centerUpdate = centerBaseUrl + '/update'
export const centerStatus = centerBaseUrl + '/toggle-status'
export const userList = '/user/user-list'
export const officeEntryStore = 'master-office/store'
export const officeEntryStorecenter = 'master-office/storecenter'
export const officeEntryStoresubzone = 'master-office/storesubzone'
export const officeEntryStoreunit = 'master-office/storeunit'
export const officeEntryUpdate = 'master-office/officeupdate'
export const officeEntryUpdatetwo = 'master-office/updatetwo'
export const officeEntryStoresubzonevalidate = 'master-office/subzonevalidation'
export const officeEntryStorecentervalidate = 'master-office/centervalidation'

export const allocationListResolutionList = ''
