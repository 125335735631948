<template>
  <b-container fluid>
    <b-row>
      <b-col lg="12" sm="12">
        <iq-card>
          <template v-slot:body>
            <b-row>
              <b-col lg="12" sm="12">
                <b-table-simple class="tg mt-3" hover small caption-top bordered>
                  <b-tbody>
                    <b-tr>
                      <b-th style="width: 15%"  class="text-right" >{{ $t('bsri_config.approval_commitee_name') }}</b-th>
                      <b-th class="text-center" style="width: 5%">:</b-th>
                      <b-td style="width: 30%" class="text-left">{{ $i18n.locale === 'bn' ? formData.approval_committee_name_bn : formData.approval_committee_name }}</b-td>
                      <b-th style="width: 15%" class="text-right" >{{ $t('bsri_config.meeting_date') }}</b-th>
                      <b-th class="text-center" style="width: 5%">:</b-th>
                      <b-td style="width: 30%" class="text-left">{{ formData.meeting_date | dateFormat }}</b-td>
                    </b-tr>
                    <b-tr>
                      <b-th style="width: 15%"  class="text-right" >{{ $t('bsri_config.meeting_venue') }}</b-th>
                      <b-th class="text-center" style="width: 5%">:</b-th>
                      <b-td style="width: 30%" class="text-left">{{ $i18n.locale === 'bn' ? formData.meeting_vanue_bn : formData.meeting_vanue }}</b-td>
                      <b-th style="width: 15%" class="text-right" >{{ $t('bsri_config.meeting_agenda') }}</b-th>
                      <b-th class="text-center" style="width: 5%">:</b-th>
                      <b-td style="width: 30%" class="text-left">{{ $i18n.locale === 'bn' ? formData.meeting_agenda_bn : formData.meeting_agenda }}</b-td>
                    </b-tr>
                      <b-tr>
                      <b-th style="width: 15%"  class="text-right" >{{ $t('bsri_config.agenda_wise_description') }}</b-th>
                      <b-th class="text-center" style="width: 5%">:</b-th>
                      <b-td style="width: 30%" class="text-left">{{ $i18n.locale === 'bn' ? formData.meeting_agenda_description_bn : formData.meeting_agenda_description }}</b-td>
                      <b-th style="width: 15%" class="text-right" >{{ $t('bsri_config.allocation_id') }}</b-th>
                      <b-th class="text-center" style="width: 5%">:</b-th>
                      <b-td style="width: 30%" class="text-left">{{ formData.allocation_id }}</b-td>
                    </b-tr>
                    <b-tr>
                      <b-th colspan="6" class="text-center text-primary">{{$t('bsri_config.allocation_info')}}</b-th>
                    </b-tr>
                    <b-tr>
                      <b-th style="width: 15%"  class="text-right" >{{ $t('elearning_config.fiscal_year') }}</b-th>
                      <b-th class="text-center" style="width: 5%">:</b-th>
                      <b-td style="width: 30%" class="text-left">{{ $i18n.locale === 'bn' ? formData.fiscal_year_bn : formData.fiscal_year }}</b-td>
                      <b-th style="width: 15%" class="text-right" >{{ $t('dae_config.season_name') }}</b-th>
                      <b-th class="text-center" style="width: 5%">:</b-th>
                      <b-td style="width: 30%" class="text-left">{{ $i18n.locale === 'bn' ? formData.season_bn : formData.season }}</b-td>
                    </b-tr>
                    <b-tr>
                      <b-th style="width: 15%"  class="text-right" >{{ $t('dae_config.subsidy_type') }}</b-th>
                      <b-th class="text-center" style="width: 5%">:</b-th>
                      <b-td style="width: 30%" class="text-left">{{ $i18n.locale === 'bn' ? formData.subsidy_type_bn : formData.subsidy_type }}</b-td>
                      <b-th style="width: 15%" class="text-right" >{{ $t('dae_config.subsidy_name') }}</b-th>
                      <b-th class="text-center" style="width: 5%">:</b-th>
                      <b-td style="width: 30%" class="text-left">{{ $i18n.locale === 'bn' ? formData.subsidy_bn : formData.subsidy }}</b-td>
                    </b-tr>
                    <b-tr>
                      <b-th style="width: 15%"  class="text-right" >{{ $t('dae_config.project_name') }}</b-th>
                      <b-th class="text-center" style="width: 5%">:</b-th>
                      <b-td style="width: 30%" class="text-left">{{ $i18n.locale === 'bn' ? formData.project_bn : formData.project }}</b-td>
                      <b-th style="width: 15%" class="text-right" ></b-th>
                      <b-th class="text-center" style="width: 5%"></b-th>
                      <b-td style="width: 30%" class="text-left"></b-td>
                    </b-tr>
                  </b-tbody>
                </b-table-simple>
              </b-col>
            </b-row>
          </template>
        </iq-card>
      <!-- <pre>{{formData}}</pre> -->
      </b-col>
    </b-row>
  </b-container>
</template>
<script>
import { core } from '@/config/pluginInit'
import flatpickr from 'flatpickr'
import { incentiveGrantServiceBaseUrl, seedFertilizerServiceBaseUrl } from '@/config/api_config'

export default {
  props: ['id'],
  created () {
    if (this.id) {
      const tmp = this.getFormData()
      this.formData = tmp
    }
  },
  mounted () {
    core.index()
    flatpickr('.fromDate', {})
  },
  data () {
    return {
      saveBtnName: this.id ? this.$t('globalTrans.update') : this.$t('globalTrans.save'),
      formData: {
        id: '',
        name: '',
        email: '',
        mobile: '',
        fiscal_year_id: 0,
        office_type_id: 0,
        office_id: 0,
        org_id: 0,
        room_type_id: 0,
        room_rent_id: 0,
        floor_id: 0,
        room_id: 0
      },
      officeTypeList: [],
      officeList: [],
      roomlist: [],
      floorlist: [],
      roomRentList: [],
      trainingCategoryList: [],
      dateErrorMsg: '',
      incentiveGrantServiceBaseUrl: incentiveGrantServiceBaseUrl,
      seedFertilizerServiceBaseUrl: seedFertilizerServiceBaseUrl,
      trainingTitleList: []
    }
  },
  computed: {
    orgList: function () {
      return this.$store.state.commonObj.organizationProfileList.filter(item => item.status === 0)
    },
    fiscalYearList: function () {
      return this.$store.state.commonObj.fiscalYearList.filter(item => item.status === 0)
    }
  },
  methods: {
    getFormData () {
      const tmpData = this.$store.state.list.find(item => item.id === this.id)
      return JSON.parse(JSON.stringify(tmpData))
    }
  }
}
</script>
